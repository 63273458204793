.editable.quill-editor .ql-container {
  border: 1px solid #ccc !important;
}

.quill-editor .ql-container {
  border: none;
}

.quill-editor h1,
.quill-editor h2,
.quill-editor h3,
.quill-editor h4,
.quill-editor h5,
.quill-editor h6 {
  color: #4e616c;
}

.info {
  h1 {
    color: var(--primary-accent);
    font-size: 1.875rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  h2 {
    color: var(--primary-accent);
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
    font-weight: bold;
  }

  h3 {
    color: var(--primary-accent);
    font-size: 1.15rem;
    margin-bottom: 0.2rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
